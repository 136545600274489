const colors = {
  darkPrimary: "#00BCD4", 
  lightPrimary: "#1976D2",
  red: "#E53935",
  limeGreen: "#CDDC39",
  black: "#121212",
  white: "#FAFAFA",
};

const common = {
  defaultPadding: "1rem",
  midPadding: "1.5rem",
  largePadding: "2rem",
  fontPrimary: "Fira Sans",
  fontMono: "Fira Mono",
  smallWidth: "700px",
};

export const themes = {
  dark: {
    ...common,
    linkColor: colors.darkPrimary,
    textColor: colors.white,
    buttonHoverTextColor: colors.black,
    secondaryColor: colors.limeGreen,
    backgroundColor: "linear-gradient(45deg, #1B2B40, #34515E)",
  },
  light: {
    ...common,
    linkColor: colors.lightPrimary,
    textColor: colors.black,
    buttonHoverTextColor: colors.white,
    secondaryColor: colors.red,
    backgroundColor: "linear-gradient(45deg, #E3F2FD, #BBDEFB)",
  },
};
