import { useState, useEffect } from 'react'

const DARK_COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'
const LIGHT_COLOR_SCHEME_QUERY = '(prefers-color-scheme: light)'

function useColorScheme() {

	const [colorScheme, setColorScheme] = useState('no-preference')

	const getBrowserColorScheme = () => {
		if (typeof window !== 'undefined') {
			if (window.matchMedia(DARK_COLOR_SCHEME_QUERY).matches) {
				return 'dark'
			}
			else if (window.matchMedia(LIGHT_COLOR_SCHEME_QUERY).matches) {
				return 'light'
			}
		}
		return 'no-preference'
	}

	useEffect(() => {
		setColorScheme(getBrowserColorScheme)
		const handler = () => setColorScheme(getBrowserColorScheme)
		const darkMatchMedia = window.matchMedia(DARK_COLOR_SCHEME_QUERY)
		const lightMatchMedia = window.matchMedia(LIGHT_COLOR_SCHEME_QUERY)

		darkMatchMedia.addEventListener('change', handler)
		lightMatchMedia.addEventListener('change', handler)

		return () => {
			darkMatchMedia.removeEventListener('change', handler)
			lightMatchMedia.removeEventListener('change', handler)
		}
	}, [])

	return colorScheme
}

export default useColorScheme
