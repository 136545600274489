import React, { useState, useLayoutEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import favicon from '../../resources/favicon.png';
import useColorScheme from '../hooks/useColorScheme';
import { themes } from '../themes';

export const ThemeContext = React.createContext();

const Page = (props) => {

  const colorScheme = useColorScheme();
  const [theme, setTheme] = useState('dark');

  const contextValue = useMemo(() => {
    const toggleTheme = () => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      setTheme(newTheme);
      localStorage.setItem('theme', newTheme);
    }

    const resetTheme = () => {
      localStorage.removeItem('theme');
      setTheme(colorScheme === 'dark' ? 'dark' : 'light');
    }
    return {theme, toggleTheme, resetTheme }
  }, [theme, colorScheme])

  useLayoutEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    } else {
      setTheme(colorScheme === 'dark' ? 'dark' : 'light');
    }
  }, [colorScheme]);

  const GlobalStyles = createGlobalStyle`
    /* @import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700');
    @import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700'); */
  
    
    html {
      box-sizing: border-box;
      font-size: 10px;
      height: 100%;
    }
    *, *:before, *:after {
      box-sizing: inherit;
      margin: 0;
      padding: 0;
    }
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      // we have background here for mobile compatibility
      background: ${themes[theme].backgroundColor};
      background-size: cover;
      pointer-events: none;
    }
    body {
      padding: 0;
      margin: 0;
      min-height:100%; 
      position:relative; 
      padding-bottom:1rem; 
      font-size: 1.8rem;
      line-height: 2;
      z-index: 1;
      font-family: ${themes[theme].fontPrimary};
      color: ${themes[theme].textColor};
    }
    a, .link_like {
      text-decoration: none;
      color: ${themes[theme].linkColor};
      cursor: pointer;
    }
    button {
      font-family: ${themes[theme].fontPrimary};
      color: ${themes[theme].textColor};
      padding: 1rem 1.5rem; 
      border: 1px solid;
      background: none;
      border-radius: 5px;
      font-size: 1.7rem;
      cursor: pointer;
      /* letter-spacing: 2px; */
      
      &:hover:not(:disabled), &:active:not(:disabled) {
        color: ${themes[theme].buttonHoverTextColor};
        background: ${themes[theme].textColor};
        transform: translateY(-2px) scale(1.02);
        transition: transform .4s;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        transform: none;
        background: none;
      }
    }
  
    ::selection {
      background: ${themes[theme].linkColor};
      color: ${themes[theme].textColor};
    }
  
  `;

  return (
    <>
      <Helmet
        defaultTitle={`Erdem Bozkurt`}
        titleTemplate={`%s | Erdem Bozkurt`}
      >
        {/* Meta tags for search engines */}
        <meta name="type" content="website" />
        <meta name="site_name" content="Erdem Bozkurt" />
        <meta name="title" content="Erdem Bozkurt" />
        <meta
          name="description"
          content="Personal website of Erdem Bozkurt"
        />
        <meta
          name="image"
          content="https://i.postimg.cc/xCkyBkQR/website-home-image.png"
        />
        {/* Meta tags for social media sites */}
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Erdem Bozkurt" />
        <meta name="og:title" content="Erdem Bozkurt" />
        <meta
          name="og:description"
          content="Personal website of Erdem Bozkurt"
        />
        <meta
          name="og:image"
          content="https://i.postimg.cc/xCkyBkQR/website-home-image.png"
        />
        <html lang="en" />
        <link rel="icon" type="image/png" href={favicon} />
        <link
          href="https://fonts.googleapis.com/css?family=Fira+Mono:400,700|Fira+Sans:400,700"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeContext.Provider value={contextValue}>
        <GlobalStyles />
        <ThemeProvider theme={themes[theme]}>
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
}

export default Page;
